import React from 'react'
import { Box, useColorMode } from "@chakra-ui/react";

export default function Header({ title, mb }) {
  const { colorMode } = useColorMode();
  const bgColor = { light: "primary", dark: "#0F2C58" };

  // console.log('header color: ', colorMode)

  return (
    <Box mb={typeof mb === 'undefined' ? 5 : mb} bg={bgColor[colorMode]} display="flex" justifyContent="center" height={150} >
      <Box marginBottom={2} fontWeight="bold" color="white" fontSize="4xl" width="xl" padding={3} marginTop="auto">
        {title || 'Set tittle'}
      </Box>
    </Box>
  )
}