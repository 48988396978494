
export function isLoggedIn() {
    if (typeof window !== 'undefined') {
        if (
            (localStorage.getItem('post_availability') === null && localStorage.getItem('elearning_availability') === null)
        ) {
            localStorage.removeItem('d_elearning')
            localStorage.removeItem('d_post')
            localStorage.removeItem('post_availability')
            localStorage.removeItem('elearning_availability')
            window.location.href = "/login"
        }
    }
}

export function in_completed(mk, assignment_id) {
    let id = mk + '_' + assignment_id;
    let current_completed = all_completed()

    return current_completed.find(el => el === id)
}

export function all_completed() {
    return JSON.parse(localStorage.getItem('all_completed')) || []
}

export function getDomain(type, setBranch) {

    let branch = setBranch ? setBranch : typeof window !== 'undefined' ? localStorage.getItem('branch') || setBranch : 'kranggan'
    let val

    if (type === 'elearning' || type === 'el') {
        switch (branch) {
            case 'yogyakarta':
                val = 'https://elearning.mercubuana-yogya.ac.id'
                break;

            default:
                val = 'https://umb-elearning.mercubuana.ac.id'
                break;
        }
    } else {
        switch (branch) {
            case 'yogyakarta':
                val = null
                break;
            default:
                val = 'https://umb-post.mercubuana.ac.id'
                break;
        }
    }

    if (typeof window !== 'undefined') {
        localStorage.setItem('branch', branch)
    }
    return val
}

export function isElAble() {
    if (typeof window !== 'undefined') {
        return (localStorage.getItem('elearning_availability') === 'true')
    } else {
        return false
    }
}

export function clear_data() {
    localStorage.removeItem('po_assigments')
    localStorage.removeItem('post_availability')
    localStorage.removeItem('elearning_availability')
    localStorage.removeItem('profile')
    localStorage.removeItem('d_post')
    localStorage.removeItem('d_elearning')

    return true
}

export const INIT_PROGRESS_BAR = () => {
    if (typeof window !== 'undefined') {
        let status = localStorage.getItem('show_progress_bar')
        if (!status) {
            return true
        } else {
            return (status === 'true')
        }

    } else {
        return true
    }
}